import request from "@/services/request";
import {mapGetters} from "vuex";
import axios from "axios";
import {saveBlobFile, saveFile} from "@/utils/saveFile";

export const setFileFunctions =  {
    data(){
        return {
            file: {
                name: 'Утверждённое положение об олимпиаде',
                types: ['application/pdf'],
            },
            downloadingFileForPrint: false,
            loadingFileToServer: {
                value: false,
                fileType: ''
            },
        }
    },
    computed: {
        ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
        token() {
            return this.getAuthData.token
        },
    },
    methods:{
        getFile() {
           throw new Error(
               ` add Function  getFile() {
      this.file = {...this.file, id: this.$attrs.value.fileId, fileType: 'ORG_APPEAL' };
    }, and if u wanna change fileFormat to data
     file: {
    name: 'Подтверждающие документы',
      types: ['application/pdf'],
       }`
           )
        },
        async uploadFile(event) {
            this.loadingFileToServer.value = true;
            this.loadingFileToServer.fileType = event.fileType;
            let fd = new FormData();
            fd.append('file', event.file);
            try {
                const res = await request({
                    endpoint: `/api/file/scan/upload?scanType=${event.fileType}`,
                    method: 'put',
                    body: fd,
                });
                this.file = {...this.file, ...res.data};
                this.loadingFileToServer.value = false;
            } catch (e) {
                this.loadingFileToServer.value = false;
                await this.addNotification({
                    notificationStatus: 'error',
                    notificationMessage: 'Ошибка загрузки',
                    timeout: 3000
                })
            }
        },
        async downloadFile(id) {
            this.uploadNow = true;
            try {
                const response = await axios.get(`/api/file/scan/download?file=${id}`, {
                    headers: {Authorization: this.token ? `Bearer ${this.token}` : ''},
                })
                this.uploadNow = false;
                saveFile({...response.data, fileBytes: response.data.file}, false);
            } catch (e) {
                this.uploadNow = false;
                console.log("e", e)
            }
        },
        async deleteFile(event) {
            try {
                const response = await request({
                    endpoint: `/api/file/scan/delete?fileId=${event.id}`,
                    method: 'DELETE',
                    token: this.token,
                });
                if (response.status === 200) {
                    this.file = {
                        name: this.file.name,
                        fileType: event.fileType,
                        types: this.file.types
                    }
                }
            } catch (e) {
                // this.uploadNow = false;
                await this.addNotification({
                    notificationStatus: 'error',
                    notificationMessage: 'Ошибка удаления',
                    timeout: 3000
                });
                console.log("e delete", e)
            }
        },
        async replaceFile(event) {
            await this.deleteFile(event)
            await this.uploadFile(event)
        },
        async downloadPrintDocument(api = '', type = '"application/pdf"') {
            this.downloadingFileForPrint = true;
            await axios.get(api, {
                responseType: "blob",
                headers: this.$scripts.Utils.authHeader(),
            })
                .then(response => saveBlobFile(response.data, "Выгрузка файлов", type))
                .catch(e => console.error(e))
                .finally(() => this.downloadingFileForPrint = false);
        },
    }
}